





































































































































































































































/* eslint-disable @typescript-eslint/no-explicit-any */
import Vue from "vue";
import DocumentViewer from "../PdfSign/DocumentViewer.vue";

import DrawingBoard from "@/components/DrawingBoard.vue";
import Errors from "../PdfSign/Errors.vue";
import { getAPI } from "@/api/axios-base";
import {
  notificationError,
  notifyError,
  notifyInfo,
  notifySuccess,
} from "@/components/Notification";
import { apiBaseUrl } from "@/enviorment";
import { AxiosError } from "axios";
import groupBy from "lodash/groupBy";
import sortBy from "lodash/sortBy";
import { mapActions, mapGetters, mapState } from "vuex";
import forEach from "lodash/forEach";
import rules from "@/components/account/rules";
import { Subtypes } from "@/models/Subtypes";

const BtnResendDocuments = () =>
  import(
    /* webpackChunkName: "btn-resend-docuemnts" */ "./buttons/BtnResendDocuments.vue"
  );

type StepType = "STEP_1" | "STEP_2" | "STEP_3";
type TokenStatus = "ACTIVE" | "USED" | "READED" | "unused";
interface Step {
  createdAt: string;
  step: StepType;
  tokenStatus: TokenStatus;
}

export default Vue.extend({
  components: {
    DocumentViewer,

    Errors,
  },
  name: "document-signing",
  props: {
    patient: Object,
  },
  data() {
    return {
      menu2: false,
      date: null,
      formValid: false,
      sendvia: "email",
      documentSignDialog: false,
      documentDeleteDialog: false,
      envelopeDelete: null,
      showErrors: false,
      errorMessage: "",
      showDocuments: false,
      dialogSignStep1: false,
      dialogDocument: false,
      loadingSignStep1: false,
      dialogstep: false,
      loading: false,
      loadingProc: false,
      dialogAdd: false,
      loadingSendStep: false,
      signature: new Blob(),
      documents: [],
      documentIsSign: false,
      step1Sended: false,
      loadingConfirmation: false,
      nomAccessory: "Accessory",
      rules,
      proceTemp: [],
      itemTemp: "",
      itemType: 0,
      body: {
        deviceUuid: "",
        procedureId: "",
      },
    };
  },
  computed: {
    ...mapState(["useDocunsing", "company"]),
    ...mapState(["dataCRM"]),
    ...mapState("crmMedicFormModule", ["patientDetail"]),
    ...mapState("crmSettingsModule", ["treatments", "surgerys", "accessories"]),

    ...mapGetters("crmConfigurationsModule", ["getDevicesAvailable"]),
    ...mapGetters(["getpatientN"]),

    containDocuments() {
      if (this.patient.envelope == undefined) {
        return false;
      }
      if (this.patient.envelope.length == 0) {
        return false;
      }
      const envl = this.patient.envelope;
      const cdoc = envl.filter((e: any) => e.documents.length > 1)[0];
      if (cdoc != undefined && cdoc != null) {
        return true;
      } else {
        return false;
      }
    },
    countCCA() {
      if (this.patient.envelope == undefined) {
        return 1;
      }
      if (this.patient.envelope.length == 0) {
        return 1;
      }
      const envl = this.patient.envelope;
      let c = 1;
      envl.forEach((ele: any) => {
        if (ele.documents.length == 1) {
          c++;
        }
      });

      return c;
    },

    typeShow: {
      get() {
        switch ((this as any).itemType) {
          case 1:
            return "Surgery";

          case 2:
            return (this as any).nomAccessory;

          default:
            return "Treatment";
        }
      },
    },
    itemsShow: {
      get() {
        switch ((this as any).itemType) {
          case 1:
            return this.surgerys;

          case 2:
            return this.accessories;

          default:
            return this.treatments;
        }
      },
    },

    enabledConfirm() {
      if ((this as any).proceTemp.length != 0) {
        return true;
      }
      return false;
    },
    enableForm(): boolean {
      return (this as any).date != null;
    },
    dateshow() {
      if ((this as any).date == null) {
        return "";
      }
      const datenew = new Date((this as any).date);
      return datenew.toISOString();
    },
    documentsStep1(): any[] {
      return [
        {
          title: this.getpatientN + " Contract",
          url: `${apiBaseUrl}/patient/getPdfContractByProcedure/${this.uuid}`,
        },
        {
          title: "Card contract",
          url: `${apiBaseUrl}/patient/getPdfCardContractByProcedure/${this.uuid}`,
        },
        {
          title: "Photo ID",
          url: `${apiBaseUrl}/patient/getPdfCardContractByProcedure/${this.uuid}`,
          procedure: this.patient.id,
        },
      ];
    },
    documentsStep2(): any[] {
      const token = this.uuid;

      return [
        {
          title: "Wellcome package",
          url: `${apiBaseUrl}/patient/getWelcomePackPdfByProcedure/${token}`,
        },
        {
          title: "Acknowledgement",
          url: `${apiBaseUrl}/patient/getAcknowledgementPdfByProcedure/${token}`,
        },
        {
          title: "Medical information",
          url: `${apiBaseUrl}/patient/getPdfMedicalInfoByProcedure/${token}`,
        },
        {
          title: "Video and photo autorization",
          url: `${apiBaseUrl}/patient/getPdfVideoPhotoByProcedure/${token}`,
        },
        {
          title: this.getpatientN + " rights",
          url: `${apiBaseUrl}/patient/getPdfPatientRightByProcedure/${token}`,
        },
      ];
    },
    documentsStep3(): any[] {
      const token = (this as any).$route.params.uuid;

      return [
        {
          title: "Jones Rx",
          url: `${apiBaseUrl}/patient/getPdfJonesRxByProcedure/${token}`,
        },
      ];
    },
    uuid: {
      get() {
        return (this as any).patient.id;
      },
    },

    invalidPrice() {
      if ((this as any).proceTemp.length == 0) {
        return null;
      }
      for (let index = 0; index < (this as any).proceTemp.length; index++) {
        if (
          (this as any).proceTemp[index].price >
            (this as any).proceTemp[index].maxValue ||
          (this as any).proceTemp[index].price <
            (this as any).proceTemp[index].minValue
        ) {
          return index;
        }
      }
      return null;
    },
    /*  amountValid: {
      get() {
        if (
          (this as any).amount >=
            Number((this as any).patient.surgeryType.minValue) &&
          (this as any).amount <=
            parseInt((this as any).patient.surgeryType.maxValue)
        ) {
          return true;
        }
        return false;
      },
    },
    amountValidTemp: {
      get() {
        if (
          (this as any).amountTemp >=
            Number((this as any).patient.surgeryType.minValue) &&
          (this as any).amountTemp <=
            parseInt((this as any).patient.surgeryType.maxValue)
        ) {
          return true;
        }
        return false;
      },
    }, */
  },
  methods: {
    ...mapActions("crmConfigurationsModule", ["actGetDevices"]),

    sendCCAuth() {
      (this as any).loading = true;
      getAPI
        .post(`/sprocedures/sendCCAuthForm/${this.patient.id}`)
        .then(() => {
          notifySuccess("Credit Card Authorization has been sended");
          this.$emit("update-patient");
          (this as any).loading = false;
        })
        .catch((error: any) => {
          let mess = error.response.data.message;

          if (mess.includes("[")) {
            mess = mess.replace("[", "");
            mess = mess.replace("]", "");
          }
          notifyError(error.response.data, `An error occurred: ${mess}`);

          (this as any).loading = false;
        });
    },

    deleteDocuments() {
      console.log("los documentos");
    },

    sendDocument() {
      (this as any).loading = true;
      if (this.dateshow == "") {
        getAPI
          .post(`/sprocedures/sendDocusignDocs/${this.patient.id}`)
          .then(() => {
            notifySuccess("Documents has been sended");
            this.$emit("update-patient");
            (this as any).loading = false;
            (this as any).closeDialog();
          })
          .catch((error: any) => {
            let mess = error.response.data.message;

            if (mess.includes("[")) {
              mess = mess.replace("[", "");
              mess = mess.replace("]", "");
            }
            notifyError(error.response.data, `An error occurred: ${mess}`);

            (this as any).loading = false;
          });
      } else {
        getAPI
          .post(`/sprocedures/sendDocusignDocs/${this.patient.id}`, {
            surgeryDate: this.dateshow,
          })
          .then(() => {
            notifySuccess("Documents has been sended");
            this.$emit("update-patient");
            (this as any).loading = false;
            (this as any).closeDialog();
          })
          .catch((error: any) => {
            let mess = error.response.data.message;

            if (mess.includes("[")) {
              mess = mess.replace("[", "");
              mess = mess.replace("]", "");
            }
            notifyError(error.response.data, `An error occurred: ${mess}`);

            (this as any).loading = false;
          });
      }
    },
    closeDialog() {
      (this as any).documentSignDialog = false;

      (this as any).date = null;
    },

    signAdminStep1() {
      const formData = new FormData();

      (this as any).loadingSignStep1 = true;
      formData.append(
        "firma",
        (this as any).signature,
        `signature-${(this as any).patient.uuid}`
      );
      getAPI({
        method: "post",
        url: "/patient/uploadAdminSign/" + (this as any).patient.id,
        data: formData,
        headers: { "Content-Type": "multipart/form-data" },
      })
        .then(() => {
          (this as any).loadingSignStep1 = false;
          (this as any).dialogSignStep1 = false;
          (this as any).documentIsSign = true;
          this.$emit("update-patient");
        })
        .catch((error: any) => {
          let mess = error.response.data.message;

          if (mess.includes("[")) {
            mess = mess.replace("[", "");
            mess = mess.replace("]", "");
          }
          notifyError(error.response.data, `An error occurred: ${mess}`);

          (this as any).loadingSignStep1 = false;
          (this as any).dialogSignStep1 = false;
        });
    },
    showDocument(step: number) {
      switch (step) {
        case 1:
          (this as any).documents = this.documentsStep1;
          break;
        case 2:
          (this as any).documents = this.documentsStep2;
          break;
        case 3:
          (this as any).documents = this.documentsStep3;
          break;
      }

      (this as any).showDocuments = true;
    },
    showDocumentDelete() {
      (this as any).documents = [];
      const envlo = this.patient.envelope;

      envlo.forEach((elem: any) => {
        elem.documents.forEach((doc: any) => {
          (this as any).documents.push({
            title: doc.name.split(".")[0],
            url: `${apiBaseUrl}/${doc.accessUrl}`,
            uuidEnvelope: elem.uuid,
          });
        });
      });
    },

    cancelDelete() {
      (this as any).documents = [];
      (this as any).documentDeleteDialog = false;
      (this as any).envelopeDelete = null;
    },

    deleteDocument() {
      (this as any).loading = true;
      getAPI
        .delete("/docusing/delete/envelope/" + (this as any).envelopeDelete)
        .then(() => {
          notifySuccess("Documents has been Deleted");
          this.$emit("update-patient");
          (this as any).loading = false;
          (this as any).cancelDelete();
        })
        .catch((error: any) => {
          let mess = error.response.data.message;

          if (mess.includes("[")) {
            mess = mess.replace("[", "");
            mess = mess.replace("]", "");
          }
          notifyError(error.response.data, `An error occurred: ${mess}`);
          (this as any).loading = false;
        });
    },
    showDocumentSign() {
      (this as any).documents = [];
      const envlo = this.patient.envelope;

      envlo.forEach((elem: any) => {
        elem.documents.forEach((doc: any) => {
          (this as any).documents.push({
            title: doc.name.split(".")[0],
            url: `${apiBaseUrl}/${doc.accessUrl}`,
          });
        });
      });

      (this as any).showDocuments = true;
    },

    sendStep(step: number, procedureId: string): void {
      (this as any).body.procedureId = procedureId;
      if ((this as any).sendvia == "email") {
        delete (this as any).body.deviceUuid;
      }

      const bodyReques = (this as any).body;

      getAPI.post(`/patient/sendstep${step}`, bodyReques).then(() => {
        notifyInfo("The documents have been sended");
        (this as any).loadingSendStep = false;

        this.$emit("update-patient");
        (this as any).cancel();
      });
    },

    showSignDialog() {
      (this as any).signature = new Blob();
      (this as any).dialogSignStep1 = true;
    },
    updatePatient() {
      (this as any).step1Sended = true;
      this.$emit("update-patient");
    },
    // Send confirmation to user
    sendConfirmation() {
      (this as any).loadingConfirmation = true;
      getAPI(`/patient/confirmStep3/${this.patient.id}`)
        .then(() => {
          (this as any).loadingConfirmation = false;
          notifySuccess("Confirmation sended successfully");
        })
        .catch(() => {
          (this as any).loadingConfirmation = false;
        });
    },
    /**
     * Get the Step status
     */
    getStatusBySteps(step: StepType): TokenStatus {
      if (this.patient.contract == null || this.patient.contract == undefined)
        return "unused";

      // Agrupar por pasos
      const steps = groupBy(this.patient.contract.emails, "step");

      // Para cada paso, ordenarlo ascendente por fecha
      forEach(steps, (stepValues, stepName) => {
        // Solo se ordena si tiene mas de un paso
        if (stepValues.length > 1) {
          const orderedByDate = sortBy(stepValues, [
            // Convertir la fecha a milisegundos
            (step: Step) => new Date(step.createdAt).getTime(),
          ]);

          steps[stepName] = orderedByDate;
        }
      });
      const currentSteps = steps[step];

      if (currentSteps == undefined) return "unused";
      // El último paso es el mas reciente
      const stepStatus = currentSteps[currentSteps.length - 1].tokenStatus;

      return stepStatus;
    },
    getColorByStepStatus(status: TokenStatus): string {
      const colors = {
        ACTIVE: "#673ab7",
        READED: "#ff5722",
        USED: "#43a047",
        unused: "#26a69a",
      };

      return colors[status];
    },
    getPrettyStatus(status: TokenStatus) {
      const statuses = {
        ACTIVE: "Sended",
        READED: "Viewed",
        USED: "Answered",
        unused: "unused",
      };

      return statuses[status];
    },
    cancel() {
      ((this as any).dialogstep = false), ((this as any).deviceUuid = "");
    },
  },
  async mounted() {
    (this as any).nomAccessory = this.dataCRM.nomenclators
      ? this.dataCRM.nomenclators.accessories
      : "Accessories";
    if ((this as any).nomAccessory == "Accessories") {
      (this as any).nomAccessory = "Accessory";
    } else {
      (this as any).nomAccessory = (this as any).nomAccessory.substring(
        0,
        (this as any).nomAccessory.length - 1
      );
    }

    if (!(this as any).useDocunsing) {
      await (this as any).actGetDevices();
    }
  },
});
