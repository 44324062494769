var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-layout',{staticClass:"pa-4",attrs:{"column":""}},[[_c('v-layout',[_c('v-spacer'),(!_vm.containDocuments)?[_c('div',{staticClass:"text-center"},[_c('v-dialog',{attrs:{"content-class":"rounded-xl","transition":"dialog-transition","max-width":"400"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"pl-2",attrs:{"color":"green","dark":"","rounded":"","depressed":""}},'v-btn',attrs,false),on),[_vm._v(" Send Documents ")])]}}],null,false,205141394),model:{value:(_vm.documentSignDialog),callback:function ($$v) {_vm.documentSignDialog=$$v},expression:"documentSignDialog"}},[_c('v-card',[_c('v-card-title',{staticClass:"\n                  text-h6\n                  font-weight-regular\n                  primary\n                  white--text\n                  d-flex\n                  align-center\n                  justify-space-between\n                  py-1\n                "},[_c('v-layout',{attrs:{"column":""}},[_c('span',[_vm._v(" Send contract to ")]),_c('small',{staticClass:"font-italic text-caption font-weight-bold"},[_vm._v(_vm._s(("" + (_vm.patientDetail.fullname))))]),_c('small',{staticClass:"font-italic text-caption font-weight-bold"},[_vm._v(_vm._s(("" + (_vm.patientDetail.email))))])]),_c('v-btn',{attrs:{"icon":"","dark":""},on:{"click":_vm.closeDialog}},[_c('v-icon',[_vm._v("mdi-window-close")])],1)],1),_c('v-card-text',[_c('v-form',{ref:"form",on:{"submit":function($event){$event.preventDefault();return _vm.sendDocument.apply(null, arguments)}},model:{value:(_vm.formValid),callback:function ($$v) {_vm.formValid=$$v},expression:"formValid"}},[_c('v-layout',{staticClass:"mt-2",attrs:{"justify-space-between":"","align-center":""}},[_c('v-col',{attrs:{"cols":"12","sm":"8","md":"8"}},[_c('v-menu',{attrs:{"close-on-content-click":false,"nudge-right":40,"transition":"scale-transition","offset-y":"","min-width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"label":"Surgery Date","prepend-icon":"mdi-calendar","readonly":""},model:{value:(_vm.date),callback:function ($$v) {_vm.date=$$v},expression:"date"}},'v-text-field',attrs,false),on))]}}],null,false,1904836267),model:{value:(_vm.menu2),callback:function ($$v) {_vm.menu2=$$v},expression:"menu2"}},[_c('v-date-picker',{on:{"input":function($event){_vm.menu2 = false}},model:{value:(_vm.date),callback:function ($$v) {_vm.date=$$v},expression:"date"}})],1)],1)],1)],1),_c('errors',{staticClass:"mt-3",attrs:{"message":_vm.errorMessage},model:{value:(_vm.showErrors),callback:function ($$v) {_vm.showErrors=$$v},expression:"showErrors"}})],1),_c('v-divider'),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"grey","width":"100","text":""},on:{"click":_vm.closeDialog}},[_vm._v(" "+_vm._s(_vm.$t("labelCancel"))+" ")]),_c('v-btn',{ref:"btnSend",attrs:{"color":"primary","loading":_vm.loading,"width":"100","depressed":"","rounded":""},on:{"click":_vm.sendDocument}},[_vm._v(" "+_vm._s(_vm.$t("labelSend"))+" ")])],1)],1)],1)],1)]:_vm._e(),(_vm.patient.envelope.length != 0)?[(_vm.showDocuments)?_c('document-viewer',{attrs:{"documents":_vm.documents},model:{value:(_vm.showDocuments),callback:function ($$v) {_vm.showDocuments=$$v},expression:"showDocuments"}}):_vm._e(),_c('v-btn',{staticClass:"mr-2",attrs:{"color":"info","disabled":_vm.patient.envelope.length == 0,"rounded":"","text":""},on:{"click":function($event){return _vm.showDocumentSign()}}},[_vm._v(" Show documents ")]),_c('v-dialog',{attrs:{"overlay":false,"persistent":"","max-width":"500px","transition":"dialog-transition"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mr-2",attrs:{"color":"error","disabled":_vm.patient.envelope.length == 0,"rounded":"","text":""},on:{"click":function($event){return _vm.showDocumentDelete()}}},'v-btn',attrs,false),on),[_vm._v(" Delete Documents ")])]}}],null,false,1938962054),model:{value:(_vm.documentDeleteDialog),callback:function ($$v) {_vm.documentDeleteDialog=$$v},expression:"documentDeleteDialog"}},[_c('v-card',{attrs:{"max-width":"500px"}},[_c('v-toolbar',{attrs:{"color":"primary"}},[_vm._v(" Select Document ")]),_c('v-card-text',[_c('v-autocomplete',{staticClass:"mt-3",attrs:{"outlined":"","dense":"","items":_vm.documents,"item-value":"uuidEnvelope","item-text":"title","label":"Documents"},model:{value:(_vm.envelopeDelete),callback:function ($$v) {_vm.envelopeDelete=$$v},expression:"envelopeDelete"}})],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"disabled":_vm.loading,"text":"","color":"error"},on:{"click":_vm.cancelDelete}},[_vm._v("Cancel")]),_c('v-btn',{attrs:{"disabled":_vm.loading || _vm.envelopeDelete == null,"loading":_vm.loading,"text":"","color":"primary"},on:{"click":_vm.deleteDocument}},[_vm._v("Delete")])],1)],1)],1)]:_vm._e(),(_vm.company == 'Miami' || _vm.company == 'miami')?[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mx-2",attrs:{"color":"primary","rounded":"","loading":_vm.loading,"dark":""},on:{"click":_vm.sendCCAuth}},'v-btn',attrs,false),on),[_vm._v(" Send CCA "+_vm._s("(#" + _vm.countCCA + ")")+" ")])]}}],null,false,4022116298)},[_c('span',[_vm._v("Send Credit Card Authorization")])])]:_vm._e()],2)]],2)}
var staticRenderFns = []

export { render, staticRenderFns }